import React from 'react';
import './App.scss';
import Logo from '../images/logo.svg'
import Miek  from '../images/meet-miek.png'

function App() {
  return (
    <div className="App">

    <header id="topbar">
        <img src={Logo} width="40" alt="logo"></img>
    </header>
    
    <section id="banner" className="grid-container grid-container--02grid"> 
      <div>
        <div className="header grid-column--full typography__h1 typography--left">
          Mettaji Kindercoach
        </div>
        <div className="typography__subtitle1 typography--left">
          Voor kids, jongeren en ouders
        </div>
        <p className="typography__body1 typography--left">
          Iedereen wil graag verbinding! Hoe zorg je er nou voor dat je kids op een gezonde wijze in verbinding staan met hun omgeving maar tegelijkertijd ook het lijntje kunnen vasthouden met hun eigen authentieke kern? 
        </p>
        <a href="mailto:annieuwkuyk@gmail.com" class="button button--large palette--primary">
          <span class="typography__body1">contact</span>
        </a>
      </div>
      <div class="images">
        <img id="meet_miek" src={Miek} alt="Miek"></img>
        <img class="sign" src={Logo} width="400" alt="Shou sign"></img>
      </div>
    </section>

    <section id="values" className="grid-container grid-container--03grid"> 
      <div class="title_bar">
        <h2 className="header grid-column--full typography__h2 typography--left typography--primary">
          Training
        </h2>
        <p className="typography__body2 typography--left typography--primary">
          In 6 sessies van 1,5 uur in 4 maanden tijd leert uw kind handvatten toe te passen die helpen om zich rustiger of juist krachtiger op te stellen.        </p>
      </div>
      <div>
        <h3 className="header grid-column--full typography__h3 typography--left typography--primary">
          Gezonde grenzen
        </h3>
        <p className="typography__body2 typography--left typography--primary">
        Ieder kind is uniek. Ik werk daarom ook niet met standaardzinnen of andere trucjes; er is niet één format. Voor iedereen ligt zijn grens ergens anders - dat zal ook per situatie nog verschillen. Hier krijgt uw kind meer zicht op. ‘Spelen met wat zich aandient.’        </p>
      </div>
      <div>
        <h3 className="header grid-column--full typography__h3 typography--left typography--primary">
          Worden wie je echt bent
        </h3>
        <p className="typography__body2 typography--left typography--primary">
        Door t aanwakkeren van de bewustwording van zijn lichaamsgewaarwordingen leert uw kind ook te schakelen IN het moment. Wat durf ik wel/ wat niet? Waar word IK blij van? Wie ben ik?        </p>
      </div>
      <div>
        <h3 className="header grid-column--full typography__h3 typography--left typography--primary">
          Je uitspreken
        </h3>
        <p className="typography__body2 typography--left typography--primary">
        Voordat je je krachtig kan uitspreken, is ‘t van belang dat je voor jezelf weet of de setting voor jou veilig genoeg is. Wat voor de een ‘peace of cake’ is kan voor de ander heel bedreigend zijn. Je lichaam geeft hiervoor ook verschillende signalen af, zoals transpireren, dichtgeknepen keel, een hoge ademhaling etc        </p>
      </div>
      <div>
        <h3 className="header grid-column--full typography__h3 typography--left typography--primary">
        Patronen veranderen
        </h3>
        <p className="typography__body2 typography--left typography--primary">
          Wanneer de basis van uw kind steviger is geworden (halverwege het traject) is het ineens veel makkelijker om bijv. zijn poker-face af te zetten en om te wisselen voor prettiger gedrag. Vaak zijn t gewoon aangeleerde overlevingsstrategieën die later niet meer nuttig blijken te zijn.        </p>
      </div>
      <div>
        <h3 className="header grid-column--full typography__h3 typography--left typography--primary">
        Doelgroep 
        </h3>
        <p className="typography__body2 typography--left typography--primary">
        Mijn training is bestemd voor iedereen tussen de 10 en 20 jaar, die wat onzeker is, ander gedrag wil aanleren, hoogsensitiviteit is, meer zelfvertrouwen wil ontwikkelen of zich gewoon krachtiger wil opstellen. Worstelt uw kind met labels als ad(h)d, hsp, ass etc... ik werk voorbij die labels maar ben er wel mee vertrouwd. Anders leren kijken naar jezelf is hierbij van belang!!
        </p>
      </div>
    </section>

  <section className="testimonial grid-container color--primary"> 
    <div>
       <h2 className="header grid-column--full typography__h2 typography--left">
       “Wat zit ze lekker in haar vel, <br />zelfs in deze bizarre tijden…”
        </h2>
        <p className="typography__subtitle1 typography--left">
          - Moeder van 16 jarige
        </p>
    </div>
    <img class="image" src={Logo} width="400" alt="logo" />
  </section>

  <section id="contact" className="grid-container grid-container--02grid"> 
    <div>
       <h2 className="header grid-column--full typography__h2 typography--left typography--primary">
          Heeft u een vraag?
        </h2>
        <p className="typography__body2 typography--left typography--primary">
          Juist ook in deze Corona-tijden kan iedereen wel een extra boost gebruiken... zowel voor kinderen als ouders heb ik fijne methodieken, praktische tools en makkelijke uitleg voor een prettiger communicatie tussen ouders en kinderen. .. graag toevoegen: of tussen kinderen onderling. Alles draait om een goed zelfbeeld van uw kind.
        </p>
    </div>
    <div>
        <h2 className="header grid-column--full typography__h3 typography--left typography--primary">
          Contactgegevens
        </h2>
        <div className="contactgegevens grid-container--02grid">
          <ul>
            <li><strong>Miek van Nieuwkuyk</strong></li>
            <li>Kindercoach sinds 2010</li>
            <li>Traumacoach sinds 2019</li>
            <li></li>
            <li><a href="tel:+31645444625">06 45 44 46 25</a></li>
            <li><a href="mailto:vannieuwkuyk@gmail.com">vannieuwkuyk@gmail.com</a></li>
          </ul>
          <ul>
            <li><strong>Praktijkadres</strong></li>
            <li>Raamweg 4</li>
            <li>2596 HL Den Haag</li>
          </ul>
        </div>
    </div>
  </section>
      
  <section id="footer" className="testimonial color--secondary"> 
    
       <h2 className="header grid-column--full typography__h2 typography--left">
       Kids krachtig weerbaar van binnenuit!
        </h2>
 
  </section>



  </div> 

  );
}

export default App;
